/* eslint-disable @typescript-eslint/no-explicit-any */
import { z } from 'zod'

import { ConversationPriorityParser, UUIDParser } from './commonParser.js'
import { LanguageSchema } from './constants.js'

const ConversationEventBaseParser = z.object({
  conversationId: UUIDParser,
  id: z.string(),
  isClinician: z.boolean(),
  timestamp: z.string().datetime(),
  userId: UUIDParser,
  userName: z.string(),
})

const MessageAttachmentParser = z.object({
  mimeType: z.string(),
  name: z.string(),
  url: z.string(),
})

const MessageAttachment = z.object({
  attachments: z.array(MessageAttachmentParser),
  content: z.string(),
  type: z.literal('ChatMessageAdded'),
})

export const ChatMessageAddedParser =
  ConversationEventBaseParser.merge(MessageAttachment)

export const ConversationAssignedParser = z.intersection(
  ConversationEventBaseParser,
  z.object({
    assignedToId: UUIDParser,
    assignedToName: z.string(),
    type: z.literal('ConversationAssigned'),
  }),
)

export const ConversationClosedParser = ConversationEventBaseParser.merge(
  z.object({
    type: z.literal('ConversationClosed'),
  }),
)

export const ConversationMovedParser = z.intersection(
  ConversationEventBaseParser,
  z.object({
    priority: ConversationPriorityParser,
    queueId: UUIDParser,
    queueTitle: z.string(),
    type: z.literal('ConversationMoved'),
  }),
)

export const ReasonForEntryParser = z.object({
  contentfulId: z.string().nullable(),
  id: UUIDParser,
  priority: ConversationPriorityParser,
  queueId: z.string(),
  title: z.string(),
})

export const ConversationReasonForEntryChangedParser = z.intersection(
  ConversationEventBaseParser,
  z.object({
    newReasonForEntryId: UUIDParser,
    newReasonForEntryTitle: z.string(),
    oldReasonForEntryId: UUIDParser,
    oldReasonForEntryTitle: z.string(),
    priority: ConversationPriorityParser.optional(),
    type: z.literal('ConversationReasonForEntryChanged'),
  }),
)

export const ConversationUnassignedParser = z.intersection(
  ConversationEventBaseParser,
  z.object({
    type: z.literal('ConversationUnassigned'),
    unassignedBySystem: z.boolean().optional(),
  }),
)

export const ConversationUpdatedParser = z.object({
  conversationId: UUIDParser,
  lastMessageTime: z.string().datetime().optional(),
  priority: ConversationPriorityParser,
  queueId: UUIDParser,
  reasonForEntryTitle: z.string(),
  type: z.literal('ConversationUpdated'),
})

export const QueueParser = z.object({
  description: z.string(),
  id: UUIDParser,
  title: z.string(),
  userGroups: z.array(z.string()),
})

export const UserParser = z.object({
  email: z.string().optional(),
  externalId: z.string(),
  id: UUIDParser,
  language: LanguageSchema,
  name: z.string(),
  permissions: z.array(z.string()).optional(),
  type: z.literal('clinician').or(z.literal('patient')),
  userGroups: z.array(z.object({ id: z.string(), name: z.string() })),
})

export const ClinicianParser = z.object({
  id: UUIDParser,
  name: z.string(),
  type: z.literal('clinician'),
})

export const PatientParser = z.object({
  id: UUIDParser,
  name: z.string(),
  pulseIds: z.string().nullable(),
  type: z.literal('patient'),
})

export const UserTypedParser = z.intersection(
  ConversationEventBaseParser,
  z.object({
    isTyping: z.boolean(),
    type: z.literal('UserTyped'),
    userName: z.string(),
  }),
)

export const ConversationEventParser = z.union([
  ChatMessageAddedParser,
  ConversationAssignedParser,
  ConversationUnassignedParser,
  ConversationClosedParser,
  ConversationMovedParser,
  ConversationReasonForEntryChangedParser,
  UserTypedParser,
])

export const InternalConversationEventParser = z.object({
  attachments: z.array(MessageAttachmentParser).optional(),
  content: z.string(),
  id: UUIDParser,
  internalConversationId: UUIDParser,
  timestamp: z.string().datetime(),
  type: z.literal('InternalChatMessageAdded'),
  userId: UUIDParser,
  userName: z.string(),
})

export const TriageParser = z.object({
  answers: z.record(z.string()).optional(),
  conversationId: z.string(),
  createdAt: z.string(),
  id: z.string(),
  pulseQuestionnaireId: z.string(),
})

export const InternalConversationParser = z.object({
  assignedToId: UUIDParser,
  assignedToName: z.string().nullable().optional(),
  closedAt: z.string().nullable().optional(),
  conversationId: UUIDParser,
  createdById: UUIDParser,
  createdByName: z.string().nullable().optional(),
  events: z.array(InternalConversationEventParser).optional(),
  id: UUIDParser,
  triage: TriageParser.optional(),
})

export const ConversationParser = z.object({
  assignedToId: UUIDParser.nullish(),
  assignedToName: z.string().nullish(),
  closedAt: z.string().datetime().nullish(),
  events: z.array(ConversationEventParser),
  id: UUIDParser,
  internalConversation: InternalConversationParser.nullish(),
  lastMessageTime: z.string().datetime().optional(),
  patient: PatientParser.nullable(),
  priority: ConversationPriorityParser,
  queueId: UUIDParser,
  reasonForEntryId: z.string().uuid().nullish(),
  reasonForEntryTitle: z.string().nullish(),
  triage: TriageParser.optional(),
  userGroupId: z.string().optional(),
})

export const InternalConversationCreatedParser = z.intersection(
  z.any(), //TODO: FIX ME
  z.object({
    type: z.literal('InternalConversationCreated'),
  }),
)

export const InternalConversationClosedParser = z.intersection(
  InternalConversationParser,
  z.object({
    type: z.literal('InternalConversationClosed'),
  }),
)

export const InitialDataParser = z.object({
  clinicians: z.array(ClinicianParser),
  conversations: z.array(ConversationParser),
  longWaitTimes: z.boolean(),
  queues: z.array(QueueParser),
  reasonsForEntry: z.array(ReasonForEntryParser),
  type: z.literal('InitialData'),
  user: UserParser,
})

export const ErrorCodeParser = z.union([
  z.literal('AccessDenied'),
  z.literal('ConversationNotFound'),
  z.literal('UserNotFound'),
  z.literal('ValidationError'),
  z.literal('UnknownError'),
])

export const DomainErrorParser = z.object({
  code: ErrorCodeParser,
  type: z.literal('Error'),
})

export const ConversationCreatedParser = z.intersection(
  ConversationEventBaseParser,
  z.object({
    conversation: ConversationParser,
    triage: TriageParser.optional(),
    type: z.literal('ConversationCreated'),
  }),
)

export const UpdateLongWaitTimes = z.object({
  id: UUIDParser,
  longWaitTimes: z.boolean(),
  type: z.literal('UpdateLongWaitTimes'),
})

export const ServerToClientMessageParser = z.union([
  ConversationEventParser,
  DomainErrorParser,
  InitialDataParser,
  ConversationCreatedParser,
  InternalConversationCreatedParser,
  InternalConversationClosedParser,
  InternalConversationEventParser,
  UpdateLongWaitTimes,
])

export type DomainError = z.infer<typeof DomainErrorParser>

export type ConversationEventBase = z.infer<typeof ConversationEventBaseParser>

export type ConversationCreatedEvent = z.infer<typeof ConversationCreatedParser>

export type ChatMessageAddedEvent = z.infer<typeof ChatMessageAddedParser>
export type ConversationAssignedEvent = z.infer<
  typeof ConversationAssignedParser
>
export type ConversationClosedEvent = z.infer<typeof ConversationClosedParser>
export type ConversationMovedEvent = z.infer<typeof ConversationMovedParser>
export type ConversationReasonForEntryChangedEvent = z.infer<
  typeof ConversationReasonForEntryChangedParser
>
export type ConversationUnassignedEvent = z.infer<
  typeof ConversationUnassignedParser
>
export type ConversationUpdatedEvent = z.infer<typeof ConversationUpdatedParser>
export type UserTypedEvent = z.infer<typeof UserTypedParser>
export type ConversationEvent = z.infer<typeof ConversationEventParser>
export type InitialData = z.infer<typeof InitialDataParser>
export type Clinician = z.infer<typeof ClinicianParser>
export type Patient = z.infer<typeof PatientParser>
export type User = Clinician | Patient
export type Conversation = z.infer<typeof ConversationParser>
export type ReasonForEntry = z.infer<typeof ReasonForEntryParser>
export type Queue = z.infer<typeof QueueParser>
export type UUID = z.infer<typeof UUIDParser>

export type ServerToClientEvent = z.infer<typeof ServerToClientMessageParser>

export type InternalConversationCreated = z.infer<
  typeof InternalConversationCreatedParser
>
export type InternalConversationClosed = z.infer<
  typeof InternalConversationClosedParser
>
export type InternalConversationEvent = z.infer<
  typeof InternalConversationEventParser
>

export type UpdateLongWaitTimes = z.infer<typeof UpdateLongWaitTimes>

// Helper function to check if an object is of our Error type. We are using this mainly
// in the try/catch clauses where the usage of ErrorCodeParser is not possible, because
// it will throw an exception if parse fails. In this case we don't want to validate
// that object is an Error, we need to check if an object possibly is an Error.
export function isDomainError(u: unknown): u is DomainError {
  return (
    !!u &&
    typeof u === 'object' &&
    (u as any).type === 'Error' &&
    typeof (u as any).code === 'string'
  )
}

export function domainError(
  code: z.infer<typeof ErrorCodeParser>,
): DomainError {
  return { code, type: 'Error' }
}
