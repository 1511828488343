import { useContext } from 'react'

import { BrochuresContext } from '@/contexts/BrochuresContext'

export const useBrochuresContext = () => {
  const context = useContext(BrochuresContext)
  if (context === undefined) {
    throw new Error(
      'useBrochuresContext must be used within a BrochuresProvider',
    )
  }
  return context
}
