export enum Permission {
  AdminDashboard = 'access:admin-dashboard',
  AdminPanel = 'access:admin-panel',
  Chat = 'access:chat',
  Frontend = 'access:clinician-frontend',
  HandTherapy = 'access:hand-therapy',
  PDFLookup = 'access:pdf-lookup',
  PatientHub = 'access:patient-hub',
  Brochures = 'access:brochures',
  PastChats = 'access:past-chats',
  Assistant = 'access:dc-assistant',
}
