export default {
  adminDashboard: {
    chats: {
      chart: {
        answeredWithin4Hours: {
          caption:
            'per maand van in totaal {{total}} gesprekken in het jaar {{year}}',
          line: {
            answeredWithin4Hours: 'Beantwoord binnen 4 uur',
          },
          title: 'Chats beantwoord binnen de eerste 4 uur',
        },
        closedOpened: {
          caption: 'per maand van in totaal {{total}} gesprekken',
          key: {
            closed: 'Gesloten',
            open: 'Open',
          },
          title: 'Gesloten versus geopend',
        },
        conversations: {
          caption: 'dit jaar {{year}}',
          title: 'Gesprekken',
        },
        reasons: {
          caption: 'per maand van in totaal {{total}} gesprekken',
          title: 'Redenen voor binnenkomst',
        },
        responseTime: {
          caption:
            'per maand van in totaal {{total}} gesprekken in het jaar {{year}}',
          line: {
            firstReplyTime: 'Tijd eerste reactie',
            openedTime: 'Geopende tijd',
          },
          title: 'Reactietijd',
          yAxis: 'uren',
        },
      },
      monthly: 'Maandelijks',
      title: 'Chats',
    },
    tabs: {
      chats: 'Chats Dashboard',
      users: 'Gebruikers Dashboard',
    },
    users: {
      actions: {
        download: 'Gebruikers downloaden',
      },
      chart: {
        activePatients: {
          caption: '{{activePatients}} van de {{patients}} deze maand',
          title: 'Actieve patiënten',
        },
        newUsersByMonth: {
          caption: 'per maand in {{year}}',
          title: 'Nieuwe patiënten',
        },
        pie: {
          clinicians: 'Klinische specialisten',
          unverified: 'Niet geverifieerd',
          verifiedPatients: 'Geverifieerde patiënten',
        },
        totalUsers: {
          caption: 'van in totaal {{users}} gebruikers',
          title: 'Gebruikers',
        },
      },
      title: 'Gebruikers',
    },
  },
  auth: {
    logout: 'Uitloggen',
    noPermissions:
      'Je hebt geen toestemming om toegang te krijgen tot deze pagina.',
  },
  brochures: {
    actions: {
      cancel: 'Annuleren',
      confirm: 'Bevestigen',
    },
    addTreatment: 'Behandeling toevoegen',
    allCategories: 'Alle categorieën',
    confirm: {
      cancel: 'Annuleren',
      confirm: 'Bevestigen',
      header: 'Bevestig behandeling',
      text: 'Weet u zeker dat u de behandeling wilt bevestigen?',
    },
    failedToEdit: 'Bewerken van brochure mislukt',
    filterPlaceholder: 'Filter op categorie',
    noBrochures:
      'Deze patiënt heeft nog geen brochures. Voeg een behandeling toe om te beginnen.',
    removeBrochures: 'Behandeling verwijderen',
    searchPlaceholder: 'Zoek in alle behandelingen',
    sidebarTitle: 'Behandelingen',
    title: 'Brochures',
  },
  chat: {
    actions: {
      approveAndSend: 'Goedkeuren en verzenden',
      cancel: 'Annuleren',
      close: 'Chat sluiten',
      edit: 'Bewerken',
      queueLabel: 'Wachtrij',
      reasonLabel: 'Reden',
      returnToQueue: 'Terug naar wachtrij',
      revert: 'Wijzigingen ongedaan maken',
      save: 'Opslaan',
      start: 'Chat beantwoorden',
    },
    assign: {
      button: 'toewijzen aan',
      confirmModal: {
        button: 'toewijzen',
        cancel: 'annuleren',
        confirm: 'toewijzen',
        header: 'Chat toewijzen?',
        text: 'Als je dit chatgesprek toewijst, dan wordt de interne chat gesloten. De andere deelnemers zien de interne chat dan niet meer. Je kunt eerdere interne chatgesprekken terugvinden bij de patiënteninformatie.',
      },
      modal: {
        button: 'toewijzen',
        header: 'Toewijzen aan',
        inputPlaceholder: 'Zoek in alle gebruikers',
      },
    },
    close: {
      cancel: 'annuleren',
      confirm: 'Chat sluiten',
      confirmAndSaveReport: 'Chat sluiten en rapport opslaan',
      conversation: {
        header: 'Chat sluiten?',
        text: 'Dit kan niet ongedaan gemaakt worden. Gesloten chatgesprekken blijven 1 week bewaard. Je vindt ze terug bij de patiënteninformatie.',
      },
      includeImages: 'Van patiënt ontvangen afbeeldingen bijvoegen',
      internal: {
        header: 'Interne chat sluiten?',
        text: 'Het chatgesprek met de patiënt blijft actief als je de interne chat sluit. Gesloten chatgesprekken blijven 1 week bewaard. Je vindt ze terug bij de patiënteninformatie.',
      },
      patientNotes: 'Notities voor in het rapport',
      saveReport: 'Rapport van deze chat opslaan',
      triageNote: 'Triage-antwoorden worden automatisch aan rapport toegevoegd',
    },
    events: {
      conversationAssignedTo: '{{user}} is toegevoegd aan de chat',
      conversationClosedBy: 'Chat is gesloten door {{user}}',
      conversationMovedToQueue: 'Chat verplaatst naar {{queue}}',
      conversationReasonForEntryChanged:
        'Reden gewijzigd van {{old}} in {{new}} door {{user}}',
      conversationUnassignedBy: '{{user}} heeft de chat verlaten',
      conversationUnassignedBySystem:
        'Chat is automatisch teruggestuurd naar de wachtrij door het systeem',
    },
    hiddenImage: 'Afbeeldingen zijn niet beschikbaar in gesloten chats',
    inbox: 'Inbox',
    inputPlaceholder: 'Schrijf een bericht',
    noActiveConversations: 'Selecteer een patiënt om te beginnen met chatten',
    notifications: {
      conversationCreated: 'Nieuw gesprek van {{user}}',
      internalConversationCreated: 'Nieuwe interne chat van {{user}}',
      messageAdded: 'Nieuw bericht van {{user}}',
    },
    ongoingConversations: 'actieve chats',
    queue: 'wachtrij',
    reasons: {
      Urgent: 'Dringend',
    },
    returnToQueue: {
      cancel: 'annuleren',
      confirm: 'verplaatsen naar wachtrij',
      header: 'Chat verplaatsen naar wachtrij?',
      text: 'Als je dit chatgesprek naar de wachtrij verplaatst, wordt de interne chat gesloten. De andere deelnemers zien de interne chat dan niet meer. Je kunt eerdere interne chatgesprekken terugvinden bij de patiënteninformatie.',
    },
    suggestion: {
      feedback: {
        defects: 'Gebreken',
        editedMessage: 'Bewerkte bericht:',
        header: 'Bewerkte reactie beoordelen',
        originalMessage: 'Oorspronkelijk bericht:',
        send: 'Bericht verzenden',
      },
      messageLabel: 'Bekijk deze gegenereerde reactie',
    },
    triage: {
      accordionTitle: 'Patient triage questions',
    },
    userTyping: '{{name}} is aan het schrijven',
  },
  generic: {
    noEmail: 'GEEN E-MAIL BESCHIKBAAR',
  },
  handTherapy: {
    actions: {
      add: 'Toevoegen',
      cancel: 'Annuleren',
      confirm: 'Programma toewijzen',
      remove: 'Verwijderen',
    },
    changeProgram: 'Wijzig programma',
    exercises: {
      button: 'Oefening toevoegen',
      frequency: 'Frequentie',
      frequencyOptions: {
        day: 'dag',
        hour: 'uur',
      },
      instructions: 'Instructies',
      locations: {
        clinic: 'In de kliniek',
        home: 'Thuis',
      },
      reps: 'Reps',
      rest: 'Rust',
      sets: 'Sets',
      title: 'Oefeningen',
      videoPreview: 'Videovoorbeeld',
    },
    notFound: {
      backTo: 'Zoek patiënten',
      title:
        'Sorry, we kunnen het programma {{programId}} niet terugvinden. Zoek de patiënt op om verder te gaan.',
    },
    sidebar: {
      allAreasLabel: 'Alle categoriën',
      allLocations: 'Alle locaties',
      area: {
        dexterity: 'Oefenruimte: behendigheid',
        elbow: 'Elleboog',
        finger: 'Vinger',
        general: 'ADL oefeningen',
        mobility: 'Oefenruimte: beweeglijkheid',
        nerve: 'Zenuw',
        shoulder: 'Schouder',
        strength: 'Oefenruimte: kracht',
        thumb: 'Duim',
        wrist: 'Pols',
      },
      areaOfConcernPlaceholder: 'Categorie',
      locations: {
        clinic: 'In de kliniek',
        home: 'Thuis',
      },
      locationsPlaceholder: 'Locatie',
      searchExercisesPlaceholder: 'Zoek in alle oefeningen',
      title: {
        exercises: 'Oefeningen',
        splints: 'Spalk',
      },
    },
    splints: {
      button: 'Spalk toevoegen',
      frequencyOptions: {
        day: 'dag',
        'day-night': 'dag en nacht',
        night: "'s nachts",
      },
      instructions: 'Instructies',
      title: 'Spalk',
      wearDuring: 'Dragen gedurende',
    },
  },
  internalChat: {
    action: 'Start interne chat',
    placeholder: 'Schrijf een bericht',
    searchbarPlaceholder: 'Zoek in alle gebruikers',
    title: 'Interne chat:',
  },
  login: {
    confirm: 'Log in',
    description: 'Log in met eCumulus om door te gaan',
  },
  navbar: {
    adminPanel: 'admin',
    chat: 'Chat',
    dashboard: 'Dashboard',
    logout: 'Uitloggen',
    patients: 'Patiënten',
    userManagements: 'User Management',
  },
  patientScreen: {
    navigation: {
      brochures: {
        description: 'Bekijk en bewerk het behandelplan van je cliënt',
        title: 'Brochures',
      },
      exercises: {
        description:
          'Maak een oefenprogramma voor je cliënt. Met instructies op maat en spalken.',
        title: 'Oefeningen',
      },
      openButton: 'openen',
      pdfLookup: {
        description: 'Bekijk, mail en print ICL’s voor deze cliënt',
        title: 'Cliëntinstructies',
      },
    },
  },
  patientSearch: {
    actions: {
      clear: 'Wissen',
    },
    instructions: 'Typ Enter om te patiënten te zoeken',
    noResults: 'Geen patiënten gevonden',
    searchbarPlaceholder: 'Zoek patiënt op naam of ID',
    title: 'Hallo, {{name}}',
  },
  patientSidebar: {
    carePath: {
      closedCarePaths: 'Afgeronde behandelingen',
      currentCarePaths: 'Lopende behandelingen',
      joinCall: 'Deelnemen',
      loadingAppointments: 'Laden...',
      nMore: '{{num}} meer',
      noActiveCarePaths: 'Geen lopende behandelingen',
      noClosedCarePaths: 'geen afgeronde behandelingen',
      noContent: 'Er zijn geen afspraken om weer te geven.',
      noMoreAppointments: 'Geen afspraken meer',
      numActiveCarePathsPlural: '{{num}} lopende behandelingen',
      numActiveCarePathsSingular: '1 lopende behandeling',
      numClosedCarePathsPlural: '{{num}} afgeronde behandelingen',
      numClosedCarePathsSingular: '1 afgeronde behandeling',
      past: 'Verleden',
      planned: 'Gepland',
      today: 'Vandaag',
    },
    chats: {
      backToList: 'Terug naar overzicht chats',
      internal: {
        title: 'Interne chat',
      },
      noContent: 'Er zijn geen chats om weer te geven.',
      noEvents: 'Er zijn geen chats om weer te geven.',
      ongoing: 'Actieve chats',
      past: 'Eerdere chats',
    },
    expandProfile: 'Patiëntprofiel  uitvouwen',
    pdfs: {
      created: 'Behandeling: {{- date}}',
      error: {
        description: 'Probeer het later opnieuw',
        title: 'Er is iets misgegaan',
      },
      noCarePlanSelected: {
        description: 'Selecteer een cliëntinstructie uit de lijst rechtsonder',
        title: 'Er zijn momenteel geen items om weer te geven',
      },
      noCarePlans: 'Geen zorgplannen gevonden',
      onGoingCarePlans: 'Cliëntinstructies lopende behandelingen',
      pastCarePlans: 'Cliëntinstructies afgeronde behandelingen',
      sendPDF: {
        cancel: 'Annuleren',
        confirm: 'Verzenden',
        error: 'Er is iets misgegaan. Probeer het later opnieuw.',
        header: 'Cliëntinstructie naar patiënt verzenden',
        success: 'De PDF is succesvol verzonden',
        text: 'Weet u zeker dat u de PDF naar het e-mailadres van de patiënt wilt sturen {{email}}?',
      },
    },
    questionnaires: {
      completed: 'Afgerond',
      expired: 'Verlopen',
      noContent: 'Er zijn geen vragenlijsten om weer te geven.',
      patient: 'Vragenlijsten patiënt',
      therapist: 'Vragenlijsten therapeut',
      todo: 'Nog invullen',
    },
    tabs: {
      carePath: 'Afspraken',
      chats: 'Chats',
      questionnaires: 'Vragenlijsten',
    },
  },
  preCall: {
    description:
      'Scan de QR-code met een mobiel apparaat om deel te nemen aan het videogesprek. Of typ de link hieronder in de browser van je mobiele apparaat en vul de code in.',
  },
  programSelection: {
    actions: {
      cancel: 'Annuleren',
      confirm: 'Kies programma',
    },
    exercise: 'Oefening',
    noPrograms: `Geen programma's gevonden`,
    searchPlaceholder: `Zoek programma's`,
    splint: 'Spalk',
    titles: {
      change: 'Programma wijzigen',
      select: 'Kies programma',
    },
  },
  userManagement: {
    actions: {
      cancel: 'Annuleren',
      delete: 'Verwijderen',
      save: 'Opslaan',
    },
    featureFlags: {
      actions: {
        add: 'Nieuwe feature flag',
      },
      columns: {
        actions: 'Acties',
        description: 'Beschrijving',
        enabled: 'Ingeschakeld',
        key: 'Sleutel',
        title: 'Titel',
        value: 'Waarde',
      },
      createModal: {
        error:
          'Er was een probleem bij het aanmaken van de nieuwe feature flag, controleer of de sleutel uniek is',
        title: 'Nieuwe feature flag aanmaken',
      },
      deleteModal: {
        caption:
          'Weet u zeker dat u de feature flag {{title}} wilt verwijderen?',
        error: 'Er was een probleem bij het verwijderen van de feature flag',
        title: 'Feature flag verwijderen',
      },
      editModal: {
        error: 'Er was een probleem bij het bijwerken van de feature flag',
        title: 'Feature flag bewerken',
      },
      expandFeatureFlag: {
        actions: {
          clearSelection: 'Reset',
          deleteSelected: 'Verwijderen',
          downloadCsv: 'Waarden downloaden',
          selectAll: 'Alles selecteren',
          uploadCsv: 'CSV uploaden',
        },
        addNewValuePlaceholder: 'Nieuwe waarde toevoegen',
        breadcrumb: 'Terug naar alle functies',
        error: {
          errorUpdatingFeatureFlag:
            'Fout bij het bijwerken van de functievlag. Probeer het opnieuw.',
          noFeatureFlagFound:
            'Functievlag met sleutel {{key}} niet gevonden. Ga terug.',
          noItemsFound: 'Geen items gevonden met {{query}}',
        },
        searchbarPlaceholder: 'Zoeken...',
        uploadFileModal: {
          actions: {
            save: 'Opslaan',
          },
          error: {
            fileNotCSV: 'Ongeldig bestandstype. Geef een CSV-bestand op',
            noFileProvided: 'Geen bestand opgegeven',
          },
          title: 'CSV-bestand uploaden',
        },
        values: 'Waarden',
      },
      title: 'Feature flags',
    },
    permissions: {
      actions: {
        add: 'Nieuwe machtiging',
      },
      columns: {
        actions: 'Acties',
        description: 'Beschrijving',
        name: 'Naam',
      },
      createModal: {
        caption:
          'Machtigingen moeten ook aan de codebase worden toegevoegd om in de applicatie te worden gebruikt',
        error:
          'Er was een probleem bij het aanmaken van de nieuwe machtiging, controleer of de naam uniek is',
        title: 'Nieuwe machtiging aanmaken',
      },
      deleteModal: {
        caption: 'Weet u zeker dat u de machtiging {{name}} wilt verwijderen?',
        error:
          'Er was een probleem bij het verwijderen van de machtiging, zorg ervoor dat deze niet in gebruik is',
        title: 'Machtiging verwijderen',
      },
      editModal: {
        error:
          'Er was een probleem bij het bijwerken van de machtiging, controleer of de naam uniek is',
        title: 'Machtiging bewerken',
      },
      title: 'Machtigingen',
    },
    queues: {
      actions: {
        add: 'Nieuwe wachtrij',
      },
      columns: {
        actions: 'Acties',
        description: 'Beschrijving',
        title: 'Titel',
        userGroups: 'Gebruikersgroepen',
      },
      createModal: {
        error:
          'Er was een probleem bij het aanmaken van de nieuwe wachtrij, controleer of de naam uniek is en alle velden zijn ingevuld',
        title: 'Nieuwe wachtrij aanmaken',
      },
      deleteModal: {
        caption: 'Weet u zeker dat u de wachtrij {{title}} wilt verwijderen?',
        error:
          'Er was een probleem bij het verwijderen van de wachtrij, controleer of er geen gebruikersgroepen aan gekoppeld zijn',
        title: 'Wachtrij verwijderen',
      },
      editModal: {
        error:
          'Er was een probleem bij het bijwerken van de wachtrij, controleer of de naam uniek is en alle velden zijn ingevuld',
        title: 'Wachtrij bewerken',
      },
      title: 'Wachtrijen',
    },
    reasons: {
      actions: {
        add: 'Nieuwe reden',
        editOrder: 'Volgorde bewerken',
        saveOrder: 'Volgorde opslaan',
      },
      columns: {
        actions: 'Acties',
        contentfulId: 'Contentful ID',
        organizations: 'Organisaties',
        priority: 'Prioriteit',
        queue: 'Wachtrij',
        title: 'Titel',
        urgent: 'Urgent',
      },
      createModal: {
        error:
          'Er was een probleem bij het aanmaken van de nieuwe reden, controleer of de titel uniek is en alle velden zijn ingevuld',
        title: 'Nieuwe reden aanmaken',
      },
      deleteModal: {
        caption: 'Weet u zeker dat u de reden {{title}} wilt verwijderen?',
        error:
          'Er was een probleem bij het verwijderen van de reden, zorg ervoor dat deze niet in gebruik is in gesprekken',
        title: 'Reden verwijderen',
      },
      editModal: {
        error:
          'Er was een probleem bij het bijwerken van de reden, controleer of de titel uniek is en alle velden zijn ingevuld',
        title: 'Reden bewerken',
      },
      title: 'Redenen',
    },
    roles: {
      actions: {
        add: 'Nieuwe rol',
      },
      columns: {
        actions: 'Acties',
        azureGroups: 'Azure Groups',
        description: 'Beschrijving',
        name: 'Naam',
        permissions: 'Machtigingen',
        value: 'Waarde',
      },
      createModal: {
        error:
          'Er was een probleem bij het aanmaken van de nieuwe rol, controleer of de naam uniek is en machtigingen aanwezig zijn',
        title: 'Nieuwe rol aanmaken',
      },
      deleteModal: {
        caption: 'Weet u zeker dat u de rol {{name}} wilt verwijderen?',
        error:
          'Er was een probleem bij het verwijderen van de rol, zorg ervoor dat deze niet in gebruik is',
        title: 'Rol verwijderen',
      },
      editModal: {
        error:
          'Er was een probleem bij het bijwerken van de rol, controleer of de naam uniek is',
        title: 'Rol bewerken',
      },
      title: 'Rollen',
    },
    tabs: {
      featureFlags: 'Feature flags',
      reasons: 'Redenen en Wachtrijen',
      roles: 'Rollen en Machtigingen',
      users: 'Gebruikers',
    },
    users: {
      actions: {
        add: 'Nieuwe gebruiker',
        download: 'Gebruikers downloaden',
      },
      columns: {
        actions: 'Acties',
        email: 'E-mail',
        lastSignIn: 'Laatste aanmelding',
        name: 'Naam',
        patientId: 'Patiënt ID',
        roles: 'Rollen',
        verified: 'Geverifieerd',
      },
      createModal: {
        error:
          'Er was een probleem bij het aanmaken van de nieuwe gebruiker, controleer of het e-mailadres uniek is en de naam aanwezig is',
        title: 'Nieuwe gebruiker aanmaken',
      },
      deleteModal: {
        caption: 'Weet u zeker dat u gebruiker {{email}} wilt verwijderen?',
        error: 'Er was een probleem bij het verwijderen van de gebruiker',
        title: 'Gebruiker verwijderen',
      },
      display: 'Weergave: {{amount}} van {{total}}',
      editModal: {
        error:
          'Er was een probleem bij het bijwerken van de gebruiker, controleer of het e-mailadres uniek is',
        title: 'Gebruiker bewerken',
      },
      title: 'Gebruikers',
    },
  },
}
