import update from 'immutability-helper'
import { createContext, PropsWithChildren, useCallback, useState } from 'react'

import { useDefaultPrograms } from '@/hooks/data/useDefaultPrograms'
import { Exercise } from '@/types/exercise'
import { Program } from '@/types/program'

export enum SidebarType {
  Exercises = 'exercises',
  PatientInfo = 'patientInfo',
  Splints = 'splints',
}

interface HandTherapyContextValues {
  actions?: {
    addExercise: (exercise: Exercise) => void
    removeExercise: (id: string) => void
    reorderExercises: (dragIndex: number, hoverIndex: number) => void
    setCurrentProgram: (program: Program | null) => void
    setIsDirty: (isDirty: boolean) => void
    updateSidebar: (sidebar: SidebarType) => void
  }
  currentProgram: Program | null
  defaultPrograms: { isLoading: boolean; programs: Program[] }
  isDirty: boolean
  sidebar: SidebarType
}

const initialState = {
  actions: undefined,
  currentProgram: null,
  defaultPrograms: { isLoading: false, programs: [] },
  isDirty: false,
  sidebar: SidebarType.PatientInfo,
}

const HandTherapyContext = createContext<HandTherapyContextValues>(initialState)

const HandTherapyProvider = ({ children }: PropsWithChildren) => {
  const [sidebar, setSidebar] = useState(initialState.sidebar)
  const [currentProgram, setCurrentProgram] = useState<Program | null>(
    initialState.currentProgram,
  )
  const [isDirty, setIsDirty] = useState(false)

  const { defaultPrograms, isLoading } = useDefaultPrograms()

  const updateSidebar = (sidebar: SidebarType) => {
    setSidebar(sidebar)
  }

  const removeExercise = useCallback(
    (id: string) => {
      if (!currentProgram) {
        return
      }

      setCurrentProgram({
        ...currentProgram,
        exercises: currentProgram.exercises.filter(
          (exercise) => exercise.contentfulId !== id,
        ),
        splints: currentProgram?.splints.filter(
          (splint) => splint.contentfulId !== id,
        ),
      })
    },
    [currentProgram],
  )

  const addExercise = useCallback(
    (exercise: Exercise) => {
      if (!currentProgram) {
        return
      }

      if (exercise.type === 'splint') {
        const splints = [...currentProgram.splints]
        splints.push(exercise)

        setCurrentProgram({ ...currentProgram, splints })
      }

      if (exercise.type === 'exercise') {
        const exercises = [...currentProgram.exercises]
        exercises.push(exercise)

        setCurrentProgram({ ...currentProgram, exercises })
      }
    },
    [currentProgram],
  )

  const reorderExercises = (dragIndex: number, hoverIndex: number) => {
    const sortedExercises = update(currentProgram?.exercises, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, currentProgram?.exercises[dragIndex] as Exercise],
      ],
    })

    if (currentProgram) {
      setCurrentProgram({ ...currentProgram, exercises: sortedExercises ?? [] })
    }
  }

  return (
    <HandTherapyContext.Provider
      value={{
        actions: {
          addExercise,
          removeExercise,
          reorderExercises,
          setCurrentProgram,
          setIsDirty,
          updateSidebar,
        },
        currentProgram,
        defaultPrograms: {
          isLoading,
          programs: defaultPrograms,
        },
        isDirty,
        sidebar,
      }}
    >
      {children}
    </HandTherapyContext.Provider>
  )
}

export { HandTherapyContext, HandTherapyProvider }
