import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Dropdown } from '@/components/common/Dropdown.tsx'
import { Stack } from '@/components/common/Stack.tsx'
import { BodyM } from '@/components/common/Text.tsx'
import { useSocket } from '@/contexts/useSocketContext.ts'
import { useContactReasons } from '@/hooks/data/useContactReasonts.ts'
import { useActiveConversation } from '@/state/hooks/useActiveConversation.ts'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'
import { useQueues } from '@/state/hooks/useQueues.ts'

import { AssignConversation } from './AssignConversation/AssignConversation.tsx'
import { CloseConversation } from './CloseConversation/CloseConversation.tsx'
import { QueuesDropdown } from './QueuesDropdown.tsx'
import { ReturnToQueue } from './ReturnConversationToQueue/ReturnToQueue.tsx'
import { StartConversation } from './StartConversation.tsx'

const ActionContainer = styled.div`
  grid-column: 2/4;
  display: flex;
  padding: 0px 20px;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  border-bottom: 1px solid ${(props) => props.theme.colors.common.border};
`

const DropdownContainer = styled.div`
  min-width: 200px;
  max-width: 500px;
  position: relative;
  padding: 10px 0;
`

export function ActionBar() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })
  const clinician = useCurrentUser()
  const activeConversation = useActiveConversation()
  const queues = useQueues()
  const { contactReasons: reasons = [] } = useContactReasons()

  const isAssignedToCurrentUser =
    activeConversation?.assignedToId === clinician.id

  const isInQueue = !activeConversation?.assignedToId

  const isInternalChat =
    activeConversation?.internalConversation?.assignedToId === clinician.id

  const { changeActiveConversationQueue, changeReasonForEntry } = useSocket()

  return (
    <ActionContainer>
      <Stack direction="row" spacing={16} alignItems="center">
        {isInternalChat ? (
          <BodyM fontWeight="bold">
            {activeConversation.reasonForEntryTitle}
          </BodyM>
        ) : (
          <QueuesDropdown
            onSelect={changeActiveConversationQueue}
            queues={queues}
            selectedQueueId={activeConversation?.queueId}
          />
        )}
        {!isInternalChat && (
          <DropdownContainer>
            <Dropdown
              items={reasons.filter(
                (reason) => reason.id !== activeConversation?.reasonForEntryId,
              )}
              onSelect={changeReasonForEntry}
              prompt={
                reasons.find(
                  (reason) =>
                    reason.id === activeConversation?.reasonForEntryId,
                )?.title
              }
              selectedItemId={activeConversation?.reasonForEntryId}
              label={t('actions.reasonLabel')}
            />
          </DropdownContainer>
        )}
      </Stack>
      {!isInternalChat && (
        <Stack direction="row" spacing={16} alignItems="center">
          <AssignConversation />
          {isAssignedToCurrentUser && (
            <>
              <ReturnToQueue />
              <CloseConversation />
            </>
          )}
          {isInQueue && <StartConversation />}
        </Stack>
      )}
    </ActionContainer>
  )
}
