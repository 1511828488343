import React from 'react'
import styled from 'styled-components'

import { TileSmall } from '@/components/common/TileSmall'
import { useHandTherapyContext } from '@/contexts/useHandTherapyContext'
import { Exercise } from '@/types/exercise'

import LocationBadges from '../LocationBadges'

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 8px));
  gap: 16px;
`

export const TileList = ({
  options,
  selectedOptions,
}: {
  options: Exercise[]
  selectedOptions: Exercise[]
}) => {
  const { actions } = useHandTherapyContext()

  const handleOnClick = (exercise: Exercise) => {
    const selected = selectedOptions.some(
      (option) => option.contentfulId === exercise.contentfulId,
    )

    if (selected) {
      actions?.removeExercise(exercise.contentfulId)
      actions?.setIsDirty(true)
      return
    }

    actions?.addExercise(exercise)
    actions?.setIsDirty(true)
  }

  return (
    <TilesContainer>
      {options
        .sort((a, b) => a.name?.localeCompare(b.name))
        .map((exercise) => (
          <React.Fragment key={exercise.contentfulId}>
            <TileSmall
              imageSrc={exercise.thumbnail}
              onClick={() => handleOnClick(exercise)}
              selected={selectedOptions.some(
                (option) => option.contentfulId === exercise.contentfulId,
              )}
              title={exercise.name}
              caption={<LocationBadges locations={exercise.locations} />}
            />
          </React.Fragment>
        ))}
    </TilesContainer>
  )
}
