import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { PatientInfo, SimplePatient } from '@/types/patient'

import { useFetchFromAPI } from './useFetchFromAPI'

export const usePatientInfo = (patientId?: string) => {
  const fetchAPI = useFetchFromAPI()
  const { i18n } = useTranslation()

  const { data: patientInfo, isLoading } = useQuery<PatientInfo>({
    queryFn: async () => fetchAPI(`admin/patients/${patientId}`),
    queryKey: ['patientInfo', patientId, i18n.language],
  })

  return { isLoading, patientInfo }
}

export const usePatientSearch = (searchTerm: string) => {
  const fetchAPI = useFetchFromAPI()

  const {
    data: patients,
    isFetching,
    refetch,
  } = useQuery<SimplePatient[]>({
    enabled: false,
    queryFn: async () => fetchAPI(`admin/patients?q=${searchTerm}`),
    queryKey: [searchTerm],
  })

  return { fetchPatient: refetch, isFetching, patients }
}
