import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxMagnifyingGlass } from 'react-icons/rx'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { CloseButton } from '@/components/common/CloseButton.tsx'
import { Dropdown } from '@/components/common/Dropdown.tsx'
import { Search } from '@/components/common/Search.tsx'
import { SidebarContainer } from '@/components/common/SidebarContainer.tsx'
import { Stack } from '@/components/common/Stack'
import { Header4 } from '@/components/common/Text.tsx'
import { SidebarType } from '@/contexts/HandTherapyContext'
import { useHandTherapyContext } from '@/contexts/useHandTherapyContext.ts'
import { useExercises } from '@/hooks/data/useExercises.ts'
import { Exercise } from '@/types/exercise.ts'

import { TileList } from './TileList'

const DropdownContainer = styled.div`
  min-width: 200px;
  position: relative;
  z-index: 999;
  flex: 1;
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ExerciseTileList = ({
  allExercises,
  exercises,
  selectedExercises,
}: {
  allExercises: Exercise[]
  exercises: Exercise[]
  selectedExercises: Exercise[]
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'handTherapy.sidebar',
  })
  const [query, setQuery] = useState<string | null>(null)
  const [areaFilter, setAreaFilter] = useState<string | undefined>()
  const [locationFilter, setLocationFilter] = useState<string | undefined>()

  const sortBySelected = (exerciseA: Exercise) => {
    return selectedExercises.find(
      (selected) => selected.contentfulId === exerciseA.contentfulId,
    )
      ? -1
      : 1
  }

  const filterByAreaOfConcern = (exercise: Exercise) =>
    areaFilter === undefined ||
    areaFilter === 'all' ||
    exercise.areasOfConcern?.includes(areaFilter)

  const filterByLocation = (exercise: Exercise) =>
    locationFilter === undefined ||
    locationFilter === 'all' ||
    exercise.locations?.includes(locationFilter)

  const filtered =
    areaFilter || locationFilter
      ? allExercises.filter(filterByAreaOfConcern).filter(filterByLocation)
      : exercises.filter(filterByAreaOfConcern).filter(filterByLocation)

  const filteredExercises = !query
    ? filtered
    : allExercises
        .filter((exercise) =>
          exercise.name.toLowerCase().includes(query.toLowerCase()),
        )
        .filter(filterByAreaOfConcern)
        .filter(filterByLocation)
        .sort(sortBySelected)

  const getAreasOfConcern = () => {
    // get area of concern filter options from available exercises
    const areasOfConcern = Array.from(
      allExercises.reduce((areas, exercise) => {
        exercise.areasOfConcern?.forEach((areaOfConcern) =>
          areas.add(areaOfConcern),
        )
        return areas
      }, new Set<string>()),
    )
      .map((area) => {
        const titleTranslation = t(
          `area.${area.toLowerCase().replaceAll(' ', '')}`,
          {
            defaultValue: area,
          },
        )

        return {
          id: area,
          title: titleTranslation,
        }
      })
      .sort((a, b) => a.title.localeCompare(b.title))

    // add all areas option
    return [{ id: 'all', title: t('allAreasLabel') }, ...areasOfConcern]
  }

  const uniqueLocations = Array.from(
    allExercises.reduce((locations, exercise) => {
      exercise.locations?.forEach((location) => {
        locations.add(location)
      })
      return locations
    }, new Set<string>()),
  )

  const exerciseLocations = [
    { id: 'all', title: t('allLocations') },
    ...uniqueLocations.map((name) => ({
      id: name,
      title: t(`locations.${name}`),
    })),
  ]

  return (
    <>
      <Search>
        <input
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t('searchExercisesPlaceholder')}
        ></input>
        <RxMagnifyingGlass />
      </Search>
      <Stack spacing={16} direction="row">
        <DropdownContainer>
          <Dropdown
            items={getAreasOfConcern()}
            onSelect={setAreaFilter}
            prompt={t('areaOfConcernPlaceholder')}
            selectedItemId={areaFilter}
          />
        </DropdownContainer>
        <DropdownContainer>
          <Dropdown
            items={exerciseLocations}
            onSelect={setLocationFilter}
            prompt={t('locationsPlaceholder')}
            selectedItemId={locationFilter}
          />
        </DropdownContainer>
      </Stack>
      <TileList
        options={filteredExercises}
        selectedOptions={selectedExercises}
      />
    </>
  )
}

export const ExercisesSidebar = ({
  type = SidebarType.Exercises,
}: {
  type?: SidebarType
}) => {
  const { state } = useLocation()
  const { exercises } = useExercises('exercise')
  const { exercises: splints } = useExercises('splint')
  const { actions, currentProgram, defaultPrograms } = useHandTherapyContext()
  const { t } = useTranslation('translation', {
    keyPrefix: 'handTherapy.sidebar',
  })

  const defaultProgram =
    state &&
    defaultPrograms.programs.find(
      (defaultProgram) =>
        defaultProgram.contentfulId === state.programContentfulId,
    )

  if (!currentProgram) {
    return <></>
  }

  const defaultExercises = defaultProgram
    ? defaultProgram.exercises
    : currentProgram.exercises

  const closeSidebar = () => {
    actions?.updateSidebar(SidebarType.PatientInfo)
  }

  return (
    <SidebarContainer>
      <Header>
        <Header4>{t(`title.${type}`)}</Header4>
        <CloseButton onClick={closeSidebar} />
      </Header>
      {type === 'exercises' ? (
        <ExerciseTileList
          allExercises={exercises ?? []}
          exercises={defaultExercises}
          selectedExercises={currentProgram.exercises ?? []}
        />
      ) : (
        <TileList
          options={splints ?? []}
          selectedOptions={currentProgram.splints ?? []}
        />
      )}
    </SidebarContainer>
  )
}
