import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxMagnifyingGlass } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'

import VerifiedIcon from '@/assets/svgs/verified.svg'
import { Badge } from '@/components/common/Badge'
import { Header1, TinyTitle } from '@/components/common/Text'
import { usePatientSearch } from '@/hooks/data/usePatient'
import { useCurrentUser } from '@/state/hooks/useCurrentUser'

import * as S from './PatientSearch.styled'

export const PatientSearch = () => {
  const clinician = useCurrentUser()
  const navigate = useNavigate()
  const [query, setQuery] = useState('')
  const [selectedPatient, setSelectedPatient] = useState<string>('')
  const [focusIndex, setFocusIndex] = useState<number>(-1)

  const { fetchPatient, patients } = usePatientSearch(query)
  const inputRef = useRef<HTMLInputElement>(null)

  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSearch',
  })

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useEffect(() => {
    navigate(`/patients/${selectedPatient}`)
  }, [selectedPatient])

  const handleSearch = () => {
    if (query.length >= 3) {
      fetchPatient()
      setFocusIndex(-1)
    } else {
      // TODO: error
      alert('search with at least 3 letters')
    }
  }

  const handleKeyDown = (keyCode: number) => {
    switch (keyCode) {
      case 13:
        if (
          focusIndex > -1 &&
          patients?.length &&
          patients.length > focusIndex
        ) {
          const patient = patients[focusIndex]
          handlePatientSelect({
            patientId: patient.id,
            pulseIds: patient.pulseIds,
          })
        } else {
          handleSearch()
        }
        break
      case 38:
        setFocusIndex(patients?.length ? Math.max(focusIndex - 1, 0) : -1)
        break
      case 40:
        setFocusIndex(
          patients?.length ? Math.min(focusIndex + 1, patients.length - 1) : -1,
        )
        break
    }
  }

  const handlePatientSelect = (params: {
    patientId: string
    pulseIds: string
  }) => {
    const idBase64 = btoa(params.pulseIds)
    setSelectedPatient(idBase64)
  }

  const handleEmptySearch = () => {
    setQuery('')
  }

  return (
    <S.Container>
      <S.Main>
        <TinyTitle>{dayjs().format('dddd D MMMM YYYY')}</TinyTitle>
        <Header1>
          {t('title', {
            name: clinician.name,
          })}
        </Header1>

        <S.Section>
          <S.SearchInputWrapper>
            <input
              ref={inputRef}
              onChange={(event) => setQuery(event.target.value)}
              onKeyDown={(event) => handleKeyDown(event.keyCode)}
              placeholder={t('searchbarPlaceholder')}
              value={query}
            />

            {query.length > 1 && (
              <S.ClearButton onClick={handleEmptySearch}>
                {t('actions.clear')}
              </S.ClearButton>
            )}

            {query.length === 0 && (
              <S.IconButton onClick={handleSearch}>
                <RxMagnifyingGlass />
              </S.IconButton>
            )}
          </S.SearchInputWrapper>

          <S.Results>
            <S.ResultList>
              {patients?.map((patient, i) => (
                <li key={patient.pulseIds}>
                  <S.Selection
                    focused={focusIndex === i}
                    onClick={() =>
                      handlePatientSelect({
                        patientId: patient.id,
                        pulseIds: patient.pulseIds,
                      })
                    }
                    onMouseOver={() => setFocusIndex(i)}
                  >
                    <S.Checkmark>
                      {patient.verified ? (
                        <img alt="verified patient" src={VerifiedIcon} />
                      ) : (
                        <></>
                      )}
                    </S.Checkmark>
                    <S.PatientName>{patient.name}</S.PatientName>
                    <S.PatientIds>
                      {patient.emmaId && (
                        <Badge variant="info">
                          <S.PatientId>Emma: {patient.emmaId}</S.PatientId>
                        </Badge>
                      )}
                      {patient.intramedId && (
                        <Badge variant="info">
                          <S.PatientId>
                            Intramed: {patient.intramedId}
                          </S.PatientId>
                        </Badge>
                      )}
                    </S.PatientIds>
                  </S.Selection>
                </li>
              ))}
            </S.ResultList>

            {patients?.length === 0 && (
              <S.InstructionText>{t('noResults')}</S.InstructionText>
            )}
            {query.length > 1 && !patients && (
              <S.InstructionText>{t('instructions')}</S.InstructionText>
            )}
          </S.Results>
        </S.Section>
      </S.Main>
    </S.Container>
  )
}
