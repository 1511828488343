import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Mail from '@/assets/svgs/mail.svg'
import MedicalCross from '@/assets/svgs/medical-cross.svg'
import Phone from '@/assets/svgs/phone.svg'
import VerifiedIcon from '@/assets/svgs/verified.svg'
import { Badge } from '@/components/common/Badge'
import { Skeleton } from '@/components/common/Skeleton.tsx'
import { Stack } from '@/components/common/Stack'
import { usePatientInfo } from '@/hooks/data/usePatient'

import { CarePlansCondensed } from './CarePlansCondensed'
import { SIDEBAR_WIDTH } from './Sidebar.styled'

interface PatientInfoProps {
  border: boolean
}
const PatientInfo = styled(Stack)<PatientInfoProps>`
  padding-bottom: 22px;
  border-bottom: ${({ border, theme }) =>
    border ? `1px solid ${theme.colors.common.border}` : 'none'};
`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.common.accentDark};
  font-weight: 500;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }
`

const Name = styled.p`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
`

const Checkmark = styled.p`
  margin-left: 8px;
`

interface WrapperProps {
  orientation: 'horizontal' | 'vertical'
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: ${({ orientation }) =>
    orientation === 'horizontal' ? 'row' : 'column'};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.common.border}`};
  justify-content: space-between;
`

interface CarePlansWrapperProps {
  orientation: 'horizontal' | 'vertical'
}
const CarePlansWrapper = styled.div<CarePlansWrapperProps>`
  width: ${({ orientation }) =>
    orientation === 'horizontal' ? `${SIDEBAR_WIDTH}px` : 'initial'};
  padding-left: ${({ orientation }) =>
    orientation === 'horizontal' ? '25px' : '0'};
`

const Identifier = styled.p`
  font-size: 16px;
  font-weight: 500;
`

const Icon = styled.img`
  width: 22px;
  height: 22px;
`

function PatientInformationContent({
  orientation,
  pulseId,
}: {
  orientation: 'horizontal' | 'vertical'
  pulseId: string
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'generic' })
  const { isLoading, patientInfo } = usePatientInfo(pulseId)

  if (isLoading) {
    return (
      <>
        <Skeleton height={28} width={160} />
        <Skeleton gap={8} width={240} />
        <Skeleton gap={32} width={240} />
        <Skeleton gap={4} width={80} />
        <Skeleton gap={16} width={160} />
        <Skeleton gap={4} width={80} />
        <Skeleton gap={32} width={160} />
      </>
    )
  }

  return (
    <>
      {patientInfo && (
        <Wrapper orientation={orientation}>
          <PatientInfo
            border={orientation === 'vertical'}
            direction="column"
            spacing={12}
          >
            <Name>
              {patientInfo.givenName} {patientInfo.familyName}
              {patientInfo.verified ? (
                <Checkmark>
                  <img alt="verified patient" src={VerifiedIcon} />
                </Checkmark>
              ) : (
                <></>
              )}
            </Name>
            {patientInfo.email && (
              <Stack alignItems="center" direction="row" spacing={12}>
                <Icon alt="mail icon" src={Mail} />
                <Link href={`email:${patientInfo.email}`}>
                  {patientInfo.email}
                </Link>
              </Stack>
            )}
            {!patientInfo.email && (
              <Stack alignItems="center" direction="row" spacing={12}>
                <Icon alt="mail icon" src={Mail} />
                {t('noEmail')}
              </Stack>
            )}
            {patientInfo.phone && (
              <Stack alignItems="center" direction="row" spacing={12}>
                <Icon alt="phone icon" src={Phone} />
                <Link href={`tel:${patientInfo.phone}`}>
                  {patientInfo.phone}
                </Link>
              </Stack>
            )}
            <Stack alignItems="center" direction="row" spacing={12}>
              <Icon alt="medical cross icon" src={MedicalCross} />
              {patientInfo.emmaId && (
                <Badge variant="default">
                  <Identifier>Emma: {patientInfo.emmaId}</Identifier>
                </Badge>
              )}
              {patientInfo.intramedId && (
                <Badge variant="default">
                  <Identifier>Intramed: {patientInfo.intramedId}</Identifier>
                </Badge>
              )}
            </Stack>
          </PatientInfo>
          <CarePlansWrapper orientation={orientation}>
            <CarePlansCondensed
              carePlans={patientInfo.carePaths ?? []}
              fixedActiveCarePlans={orientation === 'horizontal'}
            />
          </CarePlansWrapper>
        </Wrapper>
      )}
    </>
  )
}

export const PatientInformation = ({
  orientation,
  pulseIds,
}: {
  orientation?: 'horizontal' | 'vertical'
  pulseIds?: string
}) => {
  if (!pulseIds) {
    return <p>No pulse id found for patient.</p>
  }

  return (
    <PatientInformationContent
      orientation={orientation ?? 'vertical'}
      pulseId={pulseIds}
    />
  )
}
