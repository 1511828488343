import { useTranslation } from 'react-i18next'
import { RxArrowRight } from 'react-icons/rx'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { GridContainer } from '@/components/common/SidebarContainer.tsx'
import { Spinner, SpinnerContainer } from '@/components/common/Spinner.tsx'
import { BodyM, Header1 } from '@/components/common/Text.tsx'
import { Tile } from '@/components/common/Tile.tsx'
import { BrochuresSidebar } from '@/components/templates/BrochuresSidebar/BrochuresSidebar'
import { PatientSidebar } from '@/components/templates/PatientSidebar/Sidebar.tsx'
import { BrochuresProvider, SidebarType } from '@/contexts/BrochuresContext'
import { useBrochuresContext } from '@/contexts/useBrochuresContext'
import * as S from '@/screens/PatientHub/Brochures/Brochures.styled'
import { TilesContainer } from '@/screens/PatientHub/Exercises/Exercises.styled.ts'

export const BrochuresScreen = () => {
  return (
    <BrochuresProvider>
      <Content />
    </BrochuresProvider>
  )
}

const Content = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'brochures',
  })
  const {
    actions: {
      confirmUpdateBrochures,
      openBrochuresSidebar,
      removeBrochure,
      resetUpdateBrochures,
    },
    isDirty,
    isError,
    isLoading,
    patientBrochures: brochures,
    sidebar,
  } = useBrochuresContext()

  return (
    <GridContainer>
      <S.Main>
        <S.Content>
          <S.Header>
            <Header1>{t('title')}</Header1>
            <StyledActionButton
              disabled={SidebarType.Brochures === sidebar}
              onClick={openBrochuresSidebar}
              variant="Secondary"
            >
              {t('addTreatment')}
              <RxArrowRight height={24} width={24} />
            </StyledActionButton>
          </S.Header>
          {!isDirty && !isLoading && brochures.length === 0 && (
            <BodyM>{t('noBrochures')}</BodyM>
          )}
          {isError && <BodyM color="red">{t('failedToEdit')}</BodyM>}
          {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <TilesContainer>
              {brochures.map((brochure) => (
                <Tile
                  key={brochure.id}
                  imageSrc={brochure.image}
                  onClose={() => removeBrochure(brochure.id)}
                  title={brochure.title}
                />
              ))}
            </TilesContainer>
          )}
        </S.Content>
        {isDirty && (
          <S.Actions>
            <StyledActionButton
              onClick={confirmUpdateBrochures}
              variant="Confirm"
            >
              {t('actions.confirm')}
            </StyledActionButton>
            <S.CancelButton onClick={resetUpdateBrochures} variant="Secondary">
              {t('actions.cancel')}
            </S.CancelButton>
          </S.Actions>
        )}
      </S.Main>
      {sidebar === SidebarType.PatientInfo ? (
        <PatientSidebar />
      ) : (
        <BrochuresSidebar />
      )}
    </GridContainer>
  )
}
