import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import i18n from '@/i18n'
import { PatientBrochure } from '@/types/brochures'

import { useFetchFromAPI } from './useFetchFromAPI'

export const usePatientBrochures = (patientId: string) => {
  const fetchAPI = useFetchFromAPI()

  const { data: patientBrochures, isPending: isLoading } = useQuery<
    PatientBrochure[]
  >({
    queryFn: async () => fetchAPI(`admin/brochures?patientId=${patientId}`),
    queryKey: ['brochures', patientId, i18n.language],
  })

  return { isLoading, patientBrochures }
}

export const useUpdatePatientBrochures = () => {
  const queryClient = useQueryClient()
  const fetchAPI = useFetchFromAPI('POST')

  const {
    isError,
    isPending: isLoading,
    isSuccess,
    mutate: updatePatientBrochures,
    reset,
  } = useMutation({
    mutationFn: async (data: { patientId: string; brochures: string[] }) => {
      return fetchAPI(`admin/brochures`, data)
    },
    onSuccess: (_, { patientId }) => {
      queryClient.invalidateQueries({
        queryKey: ['brochures', patientId],
      })
    },
  })

  return {
    isError,
    isLoading,
    isSuccess,
    reset,
    updatePatientBrochures,
  }
}
