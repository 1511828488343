import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxMagnifyingGlass } from 'react-icons/rx'
import styled from 'styled-components'

import { CloseButton } from '@/components/common/CloseButton.tsx'
import { Dropdown } from '@/components/common/Dropdown.tsx'
import { Search } from '@/components/common/Search.tsx'
import { SidebarContainer } from '@/components/common/SidebarContainer.tsx'
import { Header4 } from '@/components/common/Text.tsx'
import { TileSmall } from '@/components/common/TileSmall.tsx'
import { useBrochuresContext } from '@/contexts/useBrochuresContext'
import { useBrochures } from '@/hooks/data/useBrochures'
import { usePatientPulseIdFromParams } from '@/state/hooks/usePatientPulseId.ts'

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 8px));
  gap: 16px;
`

const DropdownContainer = styled.div`
  min-width: 200px;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.dropdown};
`
export const BrochuresSidebar = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'brochures',
  })
  const { patientId } = usePatientPulseIdFromParams()
  const { brochures } = useBrochures()
  const {
    actions: { addBrochure, closeBrochuresSidebar, removeBrochure },
    patientBrochures,
  } = useBrochuresContext()

  const [query, setQuery] = useState<string | undefined>()
  const [categoryFilter, setCategoryFilter] = useState<string>('all')

  const categories = [
    ...new Set(brochures?.map((brochure) => brochure.category)),
  ].map((category) => ({
    id: category,
    title: category,
  }))

  const filteredBrochures = brochures
    ?.filter((brochure) => {
      return query
        ? brochure.title.toLowerCase().includes(query.toLowerCase())
        : true
    })
    .filter((brochure) => {
      return categoryFilter && categoryFilter !== 'all'
        ? brochure.category === categoryFilter
        : true
    })

  if (!patientId) {
    return <></>
  }
  return (
    <SidebarContainer>
      <Header>
        <Header4>{t('sidebarTitle')}</Header4>
        <CloseButton onClick={closeBrochuresSidebar} />
      </Header>
      <Search>
        <input
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t('searchPlaceholder')}
        ></input>
        <RxMagnifyingGlass />
      </Search>
      {categories && categories.length > 0 && (
        <DropdownContainer>
          <Dropdown
            items={[
              {
                id: 'all',
                title: t('allCategories'),
              },
              ...categories,
            ]}
            onSelect={setCategoryFilter}
            prompt={t('filterPlaceholder')}
            selectedItemId={categoryFilter}
          />
        </DropdownContainer>
      )}
      <TilesContainer>
        {filteredBrochures?.map((brochure) => {
          const selected = !!patientBrochures?.find(
            (patientBrochure) => patientBrochure.id === brochure.id,
          )

          return (
            <TileSmall
              key={brochure.id}
              imageSrc={brochure.image}
              onClick={() =>
                selected ? removeBrochure(brochure.id) : addBrochure(brochure)
              }
              selected={selected}
              title={brochure.title}
            />
          )
        })}
      </TilesContainer>
    </SidebarContainer>
  )
}
