import {
  createContext,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useBrochures } from '@/hooks/data/useBrochures'
import {
  usePatientBrochures,
  useUpdatePatientBrochures,
} from '@/hooks/data/usePatientBrochures'
import { usePatientPulseIdFromParams } from '@/state/hooks/usePatientPulseId.ts'
import { Brochure } from '@/types/brochures'

enum SidebarType {
  PatientInfo = 'patientInfo',
  Brochures = 'brochures',
}

interface BrochuresContextType {
  actions: {
    addBrochure: (brochure: Brochure) => void
    closeBrochuresSidebar: () => void
    confirmUpdateBrochures: () => void
    openBrochuresSidebar: () => void
    removeBrochure: (id: string) => void
    resetUpdateBrochures: () => void
  }
  isDirty: boolean
  isError: boolean
  isLoading: boolean
  isSuccess: boolean
  patientBrochures: Brochure[]
  sidebar: SidebarType
}

const BrochuresContext = createContext<BrochuresContextType | undefined>(
  undefined,
)

const BrochuresProvider = ({ children }: PropsWithChildren) => {
  const { patientId } = usePatientPulseIdFromParams()
  const { patientBrochures } = usePatientBrochures(patientId || '')
  const { brochures: allBrochures, isLoading } = useBrochures()
  const {
    isError,
    isLoading: isLoadingUpdate,
    isSuccess,
    updatePatientBrochures,
  } = useUpdatePatientBrochures()

  const initialBrochures = useRef<Brochure[]>()

  const [sidebar, setSidebar] = useState(SidebarType.PatientInfo)
  const [brochures, setBrochures] = useState<Brochure[]>([])
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    const matchBrochures = patientBrochures
      ?.map((patientBrochure) => {
        return allBrochures?.find(
          (brochure) => brochure.id === patientBrochure.contentfulId,
        )
      })
      .filter((brochure) => brochure) as Brochure[]

    if (matchBrochures) {
      initialBrochures.current = matchBrochures
      setBrochures(matchBrochures)
    }
  }, [allBrochures, patientBrochures])

  useEffect(() => {
    if (isError) {
      resetUpdateBrochures()
    }
  }, [isError])

  const addBrochure = (brochure: Brochure) => {
    setBrochures([...brochures, brochure])
    setIsDirty(true)
  }

  const removeBrochure = (id: string) => {
    setBrochures(brochures.filter((brochure) => brochure.id !== id))
    setIsDirty(true)
  }

  const closeBrochuresSidebar = () => {
    setSidebar(SidebarType.PatientInfo)
  }

  const openBrochuresSidebar = () => {
    setSidebar(SidebarType.Brochures)
  }

  const confirmUpdateBrochures = () => {
    setIsDirty(false)
    updatePatientBrochures({
      brochures: brochures.map(({ id }) => id),
      patientId: patientId!,
    })
  }

  const resetUpdateBrochures = () => {
    setIsDirty(false)
    setBrochures(initialBrochures.current || [])
  }

  return (
    <BrochuresContext.Provider
      value={{
        actions: {
          addBrochure,
          closeBrochuresSidebar,
          confirmUpdateBrochures,
          openBrochuresSidebar,
          removeBrochure,
          resetUpdateBrochures,
        },
        isDirty,
        isError,
        isLoading: isLoading || isLoadingUpdate,
        isSuccess,
        patientBrochures: brochures,
        sidebar,
      }}
    >
      {children}
    </BrochuresContext.Provider>
  )
}

export { BrochuresContext, BrochuresProvider, SidebarType }
