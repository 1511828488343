import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Brochure } from '@/types/brochures'

import { useFetchFromAPI } from './useFetchFromAPI'

export const useBrochures = () => {
  const fetchAPI = useFetchFromAPI()
  const { i18n } = useTranslation()

  const { data: brochures, isLoading } = useQuery<Brochure[]>({
    queryFn: async () => fetchAPI(`admin/brochures/all`),
    queryKey: ['brochures', i18n.language],
  })

  return { brochures, isLoading }
}
