import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Home from '@/assets/svgs/home.svg'
import MedicalCross from '@/assets/svgs/medical-cross.svg'
import { Badge } from '@/components/common/Badge'
import { Stack } from '@/components/common/Stack'

const Container = styled(Stack)`
  white-space: nowrap;
`

const BadgeIcon: { [key: string]: string } = {
  clinic: MedicalCross,
  home: Home,
}

type LocationBadgesProps = {
  locations?: string[]
}

export default function LocationBadges({ locations }: LocationBadgesProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'handTherapy',
  })

  return (
    <Container spacing={6} direction="row" alignItems="center">
      {locations?.sort()?.map((name) => (
        <Badge variant="default" key={name}>
          <Stack spacing={4} direction="row" alignItems="center" key={name}>
            <img alt="" src={BadgeIcon[name]} />
            {t(`exercises.locations.${name}`)}
          </Stack>
        </Badge>
      ))}
    </Container>
  )
}
