import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Exercise } from '@/types/exercise'

import { useFetchFromAPI } from './useFetchFromAPI'

export const useExercises = (type: string) => {
  const fetchAPI = useFetchFromAPI()
  const { i18n } = useTranslation()

  const { data: exercises, isLoading } = useQuery<Exercise[]>({
    queryFn: async () => fetchAPI(`admin/hand-therapy/exercises?type=${type}`),
    queryKey: ['exercises', type, i18n.language],
  })

  return { exercises, isLoading }
}
