import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { RawDropdown } from '@/components/common/Dropdown.tsx'
import { BodyM } from '@/components/common/Text.tsx'
import { Queue } from '@/state/stateType.ts'

const DropdownContainer = styled.div`
  min-width: 200px;
  max-width: 500px;
  position: relative;
  padding: 10px 0;
`

const QueueTitle = styled.p`
  font-weight: 500;
  margin-bottom: ${(props) => `${props.theme.spacing}px`};
`

const UserGroups = styled.div`
  color: ${(props) => props.theme.colors.common.selected};
`

export const QueuesDropdown = ({
  className,
  onSelect,
  queues,
  selectedQueueId,
}: {
  className?: string
  onSelect: (id: string) => void
  queues: Queue[]
  selectedQueueId?: string
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })
  const filteredQueues = queues.filter((q) => q.id !== selectedQueueId)
  const selectedQueue = queues.find((q) => q.id === selectedQueueId)

  if (filteredQueues.length === 0 && selectedQueue) {
    return <BodyM fontWeight="bold">{selectedQueue.title}</BodyM>
  }

  return (
    <DropdownContainer className={className}>
      <RawDropdown<Queue>
        items={filteredQueues}
        onSelect={onSelect}
        prompt={selectedQueue?.title}
        renderItem={(item) => (
          <>
            <QueueTitle>{item.title}</QueueTitle>
            <UserGroups>{item.userGroups.join(', ')}</UserGroups>
          </>
        )}
        selectedItemId={selectedQueueId}
        label={t('actions.queueLabel')}
      />
    </DropdownContainer>
  )
}
