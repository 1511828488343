import { useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RxArrowRight } from 'react-icons/rx'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { EditLinkButton } from '@/components/common/LinkButton.tsx'
import { Header1, Header3 } from '@/components/common/Text.tsx'
import { SplintTile } from '@/components/templates/Exercises/SplintTile.tsx'
import { Loader } from '@/components/templates/Loader'
import { SidebarType } from '@/contexts/HandTherapyContext'
import { useHandTherapyContext } from '@/contexts/useHandTherapyContext'
import {
  useCreatePatientProgram,
  usePatientProgram,
  useUpdatePatientProgram,
} from '@/hooks/data/usePatientProgram'
import {
  selectExercisesPath,
  SelectExercisesPathParams,
  selectPatientPath,
  SelectPatientPathParams,
  selectProgramPath,
} from '@/router/paths'
import { ExerciseTilesSection } from '@/screens/PatientHub/Exercises/ExerciseTilesSection.tsx'
import { usePatientPulseIdFromParams } from '@/state/hooks/usePatientPulseId.ts'

import * as S from './Exercises.styled.ts'
import { mapFormDefaultValues, mapFormExercise, mapFormSplint } from './mappers'
import { ProgramNotFound } from './ProgramNotFound.tsx'

export type FormData = Record<string, any>

export const ExerciseScreen = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const {
    actions,
    currentProgram,
    defaultPrograms,
    isDirty: isSidebarDirty,
    sidebar,
  } = useHandTherapyContext()
  const { programId } = useParams<SelectExercisesPathParams>()
  const { patientId } = usePatientPulseIdFromParams() as SelectPatientPathParams
  const { fetchPatientProgram, isLoading, program } =
    usePatientProgram(patientId)

  const {
    createPatientProgram,
    isError: isCreateError,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
    newPatientProgram,
  } = useCreatePatientProgram()
  const {
    isError: isUpdateError,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
    updatePatientProgram,
  } = useUpdatePatientProgram()

  const { t } = useTranslation('translation', {
    keyPrefix: 'handTherapy',
  })

  const methods = useForm({
    defaultValues: () => {
      return fetchPatientProgram().then(({ data }) => {
        if (data && !state?.assignNew) {
          return mapFormDefaultValues(data)
        }

        const defaultProgram = defaultPrograms.programs.find(
          (defaultProgram) =>
            defaultProgram.contentfulId === state.programContentfulId,
        )

        if (defaultProgram) {
          return mapFormDefaultValues(defaultProgram)
        }
      })
    },
  })

  useEffect(() => {
    if (state) {
      // if there's no current program in state
      const newProgram = currentProgram
        ? defaultPrograms.programs.find(
            (defaultProgram) =>
              defaultProgram.contentfulId === state.programContentfulId,
          )
        : program

      if (newProgram) {
        actions?.setCurrentProgram(newProgram)
      }
      // enable assign button when assigning a new plan
      if (state.assignNew) {
        actions?.setIsDirty(true)
      }
    }

    // when unmounting, refetch patient programs and empty current program state so data isn't stale
    return () => {
      fetchPatientProgram()
      actions?.setCurrentProgram(null)
      actions?.updateSidebar(SidebarType.PatientInfo)
      actions?.setIsDirty(false)
    }
  }, [])

  useEffect(() => {
    if (newPatientProgram) {
      navigate(
        selectExercisesPath({ patientId, programId: newPatientProgram.id }),
        { replace: true },
      )
    }
  }, [newPatientProgram])

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      methods.reset(methods.getValues())
      actions?.setIsDirty(false)
    }
  }, [isCreateSuccess, isUpdateSuccess])

  if (isLoading) {
    return <Loader />
  }

  if (!currentProgram) {
    return <ProgramNotFound />
  }

  const handleChangeProgram = () => {
    actions?.setCurrentProgram(null)
    actions?.updateSidebar(SidebarType.PatientInfo)
    navigate(selectProgramPath({ patientId, programId }))
  }

  const handleConfirm = (formData: FormData) => {
    const exercises = currentProgram.exercises.map((exercise) =>
      mapFormExercise({ exercise, formData }),
    )

    const splints = currentProgram.splints.map((splint) =>
      mapFormSplint({ formData, splint }),
    )

    const newProgram = {
      contentfulId: currentProgram.contentfulId,
      exercises,
      name: currentProgram.name,
      patientId,
      splints,
    }

    if (!programId) {
      createPatientProgram({ program: newProgram })
    } else {
      updatePatientProgram({
        program: newProgram,
        programId,
      })
    }
  }

  // TODO: handle cancel, reset selections if has program, what if doesn't?
  const handleCancel = () => {
    navigate(selectPatientPath({ patientId }))
    actions?.setCurrentProgram(null)
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <FormProvider {...methods}>
        <S.Main>
          <form onSubmit={methods.handleSubmit(handleConfirm)}>
            <S.Content>
              <S.Header>
                <Header1>{currentProgram.name}</Header1>
                <EditLinkButton
                  label={t('changeProgram')}
                  onClick={handleChangeProgram}
                />
              </S.Header>
              <S.Section>
                <S.SectionHeader>
                  <Header3>{t('splints.title')}</Header3>
                  <StyledActionButton
                    disabled={SidebarType.Splints === sidebar}
                    onClick={() => actions?.updateSidebar(SidebarType.Splints)}
                    type="button"
                    variant="Secondary"
                  >
                    {t('splints.button')}
                    <RxArrowRight height={24} width={24} />
                  </StyledActionButton>
                </S.SectionHeader>
                {currentProgram.splints?.length > 0 && (
                  <S.TilesContainer>
                    {currentProgram.splints.map((splint) => (
                      <SplintTile key={splint.contentfulId} splint={splint} />
                    ))}
                  </S.TilesContainer>
                )}
              </S.Section>
              <ExerciseTilesSection />
            </S.Content>
            <S.Actions>
              <StyledActionButton
                disabled={
                  !currentProgram ||
                  !(methods.formState.isDirty || isSidebarDirty)
                }
                type="submit"
                variant="Confirm"
              >
                {t('actions.confirm')}
              </StyledActionButton>
              <S.CancelButton onClick={handleCancel} variant="Secondary">
                {t('actions.cancel')}
              </S.CancelButton>
              {isCreateError && <p>failed to assign :(</p>}
              {isUpdateError && <p>failed to update :(</p>}
              {(isCreateLoading || isUpdateLoading) && <p>loading...</p>}
              {(isCreateSuccess || isUpdateSuccess) && <p>saved!</p>}
            </S.Actions>
          </form>
        </S.Main>
      </FormProvider>
    </DndProvider>
  )
}
