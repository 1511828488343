import { AppState } from '@/state/stateType'
import { setProps } from '@/util/object'
import { ConversationReasonForEntryChangedEvent } from '~common/serverToClientParser'

import mapStateConversations from './util'

export default function handleConversationReasonForEntryChanged(
  state: AppState,
  ev: ConversationReasonForEntryChangedEvent,
): AppState {
  return mapStateConversations(
    ev.conversationId,
    (c) =>
      setProps(c, {
        events: c.events?.concat(ev),
        priority: ev.priority,
        reasonForEntryId: ev.newReasonForEntryId,
      }),
    state,
  )
}
