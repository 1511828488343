import { Outlet } from 'react-router-dom'

import { GridContainer } from '@/components/common/SidebarContainer.tsx'
import { ExercisesSidebar } from '@/components/templates/Exercises/Sidebar/ExercisesSidebar'
import { PatientSidebar } from '@/components/templates/PatientSidebar/Sidebar.tsx'
import {
  HandTherapyProvider,
  SidebarType,
} from '@/contexts/HandTherapyContext.tsx'
import { useHandTherapyContext } from '@/contexts/useHandTherapyContext.ts'
import { usePatientPulseIdFromParams } from '@/state/hooks/usePatientPulseId.ts'

export const HandTherapy = () => {
  return (
    <HandTherapyProvider>
      <Content />
    </HandTherapyProvider>
  )
}

const Content = () => {
  const { sidebar } = useHandTherapyContext()
  const { patientId } = usePatientPulseIdFromParams()

  return (
    <GridContainer>
      <Outlet />
      {sidebar === SidebarType.PatientInfo ? (
        <PatientSidebar pulseIds={patientId} />
      ) : (
        <ExercisesSidebar type={sidebar} />
      )}
    </GridContainer>
  )
}
