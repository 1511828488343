import styled, { css } from 'styled-components'

type BadgeProps = {
  variant: 'warning' | 'info' | 'default'
}

const BaseBadge = styled.span<BadgeProps>`
  color: white;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 20px;
  align-items: center;
`

export const Badge = styled(BaseBadge)`
  ${(props) => {
    switch (props.variant) {
      case 'warning':
        return css`
          background-color: ${props.theme.colors.common.alert};
        `

      case 'info':
        return css`
          background-color: ${props.theme.colors.primary.main};
          color: ${props.theme.colors.primary.text};
        `

      case 'default':
        return css`
          background-color: ${props.theme.colors.common.background};
          color: ${props.theme.colors.common.black};
        `
    }
  }};
`
