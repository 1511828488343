import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Program } from '@/types/program'

import { useFetchFromAPI } from './useFetchFromAPI'

export const useDefaultPrograms = () => {
  const fetchAPI = useFetchFromAPI()
  const { i18n } = useTranslation()

  const {
    data: defaultPrograms,
    isLoading,
    refetch,
  } = useQuery<Program[]>({
    queryFn: async () => fetchAPI(`admin/hand-therapy/default-programs`),
    queryKey: ['default-programs', i18n.language],
  })

  return {
    defaultPrograms: defaultPrograms ?? [],
    fetchDefaultPrograms: refetch,
    isLoading,
  }
}
