import { last } from 'lodash'

import { Attachment } from '~common/clientToServerParser'
import { ConversationPriority } from '~common/commonParser'

import { AppState, Conversation } from './stateType'

const chatMessageAdded = 'ChatMessageAdded'

export function getAmountOfLatestCustomerMessages(c: Conversation): number {
  const chatMessages = c.events.filter((e) => e.type === chatMessageAdded)

  const lastClinicianMessageIndex = chatMessages
    .sort((x, y) => (new Date(x.timestamp) < new Date(y.timestamp) ? 1 : -1))
    .findIndex((e) => e?.isClinician)

  const noClinicianMessages = lastClinicianMessageIndex === -1

  return noClinicianMessages ? chatMessages.length : lastClinicianMessageIndex
}

export function getConversationsAssignedToMe(state: AppState): Conversation[] {
  return sortConversations(
    state.conversations.filter(
      (c) =>
        (c.assignedToId !== undefined &&
          !c.closedAt &&
          c.assignedToId === state.user.id) ||
        c.internalConversation?.assignedToId === state.user.id,
    ),
  )
}

export function getUnassignedConversations(state: AppState): Conversation[] {
  return sortConversations(
    state.conversations.filter((c) => !c.assignedToId && !c.closedAt),
  )
}

export function getConversationAttachments(
  conversation: Conversation | undefined,
) {
  const attachments = conversation?.events.reduce<Attachment[]>(
    (acc, event) => {
      if (
        event &&
        event.type === chatMessageAdded &&
        event.attachments.length > 0
      ) {
        acc = acc.concat(event.attachments)
      }

      return acc
    },
    [],
  )

  return attachments ?? []
}

export function getActiveConversation(
  state: AppState,
): Conversation | undefined {
  return state.activeConversationId !== undefined
    ? state.conversations.find((c) => c.id === state.activeConversationId)
    : undefined
}

export function getPatientLastMessageTime(c: Conversation): Date | undefined {
  const ts = last(
    c.events.filter((e) => e.type === chatMessageAdded && !e?.isClinician),
  )?.timestamp

  return ts ? new Date(ts) : undefined
}

// Note: Mutates the given array
export function sortConversations(c: Conversation[]): Conversation[] {
  return c.sort(orderByPriorityClinicianAndLastMessage)
}

const priorityOrder: Record<ConversationPriority, number> = {
  high: 2,
  low: 0,
  medium: 1,
}

function orderByPriorityClinicianAndLastMessage(
  a: Conversation,
  b: Conversation,
): number {
  const priorityDiff = priorityOrder[b.priority] - priorityOrder[a.priority]
  if (priorityDiff !== 0) {
    return priorityDiff
  }

  const aHasBeenAnswered = last(
    a.events.filter((e) => e.type === chatMessageAdded),
  )?.isClinician

  const bHasBeenAnswered = last(
    b.events.filter((e) => e.type === chatMessageAdded),
  )?.isClinician

  if (aHasBeenAnswered && !bHasBeenAnswered) {
    return 1
  }

  if (!aHasBeenAnswered && bHasBeenAnswered) {
    return -1
  }

  const patientAMessageTime = getPatientLastMessageTime(a)
  if (!patientAMessageTime) {
    return 1
  }

  const patientBMessageTime = getPatientLastMessageTime(b)
  if (!patientBMessageTime) {
    return -1
  }

  return patientBMessageTime.getTime() - patientAMessageTime.getTime()
}
